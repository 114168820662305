<template>
  <Layout class="recharge-pages">
    <BHeader>
      <h1>账号充值</h1>
    </BHeader>
    <el-tabs
      v-model="currentTabName"
      tab-position="left"
    >
      <el-tab-pane
        label="金币"
        name="netboom"
      >
        <el-form
          ref="netboomForm"
          :model="formData"
          :rules="netboomFormRule"
          label-width="100px"
          style="width: 360px"
        >
          <el-form-item
            prop="userId"
            label="User ID"
          >
            <el-input
              v-model="formData.userId"
              placeholder="充值账号ID"
            />
          </el-form-item>
          <el-form-item
            prop="gold"
            label="金币数"
          >
            <el-input
              v-model.number="formData.gold"
              placeholder="充值金币数量"
            >
              <template slot="append">
                个
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            prop="isGoldExpiration"
            label="有效期"
          >
            <el-switch
              v-model="formData.isGoldExpiration"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="openGoldExpiration"
              :inactive-value="closeGoldExpiration"
            />
          </el-form-item>
          <el-form-item
            prop="monthCount"
            label="有效期时间"
          >
            <el-input
              v-model.number="formData.timeCount"
              style="width: 260px;"
            >
              <el-select
                slot="append"
                v-model="formData.timeType"
                placeholder="请选择"
                style="width: 65px;"
              >
                <el-option
                  v-for="item in TypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loading"
              type="primary"
              style="width: 100%;"
              @click="doRecharge('gold')"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane
        label="时长"
        name="bifrost"
      >
        <el-form
          ref="bifrostForm"
          :model="formData"
          :rules="bifrostFormRule"
          label-width="100px"
          style="width: 360px"
        >
          <el-form-item
            prop="userId"
            label="User ID"
          >
            <el-input
              v-model="formData.userId"
              style="width: 260px;"
              placeholder="充值账号ID"
            />
          </el-form-item>
          <el-form-item
            prop="time"
            label="时长卡"
          >
            <div style="display: flex">
              <el-input
                v-model.number="formData.time"
                placeholder="充值时长"
                style="width: 260px;"
                :max="62 * 24"
              >
                <template slot="append">
                  小时
                </template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loading"
              type="primary"
              style="width: 100%;"
              @click="doRecharge('time')"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane> -->
      <el-tab-pane
        label="订阅时长"
        name="supplement"
      >
        <el-form
          ref="supplementForm"
          :model="formData"
          :rules="supplementFormRule"
          label-width="100px"
          style="width: 360px"
        >
          <el-form-item
            prop="userId"
            label="User ID"
          >
            <el-input
              v-model="formData.userId"
              style="width: 260px;"
              placeholder="充值账号ID"
            />
          </el-form-item>
          <el-form-item
            prop="starTime"
            label="开始时间"
          >
            <el-date-picker
              v-model="formData.starTime"
              type="datetime"
              placeholder="开始时间"
              formate="timestamp"
              style="width: 100%"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-form-item
            prop="monthCount"
            label="时间"
          >
            <el-input
              v-model.number="formData.timeCount"
              style="width: 260px;"
              placeholder="补单月数"
            >
              <el-select
                slot="append"
                v-model="formData.timeType"
                placeholder="请选择"
                style="width: 65px;"
              >
                <el-option
                  v-for="item in TypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>

              <!--              <template slot="append">-->
              <!--                月-->
              <!--              </template>-->
            </el-input>
          </el-form-item>
          <el-form-item
            prop="addTime"
            label="时长"
          >
            <div style="display: flex">
              <el-input
                v-model.number="formData.addTime"
                placeholder="补单小时数"
                style="width: 260px;"
                :max="62 * 24"
              >
                <template slot="append">
                  小时
                </template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loading"
              type="primary"
              style="width: 100%;"
              @click="doRecharge('supplement')"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane
        label="VR时长(带有效期)"
        name="vrLimitTime"
      >
        <el-form
          ref="vrLimitTimeForm"
          :model="formData"
          :rules="vrLimitTimeFormRule"
          label-width="100px"
          style="width: 360px"
        >
          <el-form-item
            prop="userId"
            label="User ID"
          >
            <el-input
              v-model="formData.userId"
              style="width: 260px;"
              placeholder="充值账号ID"
            />
          </el-form-item>
          <el-form-item
            prop="time"
            label="时长"
          >
            <div style="display: flex">
              <el-input
                v-model.number="formData.time"
                placeholder="补单小时数"
                style="width: 260px;"
                :max="62 * 24"
              >
                <template slot="append">
                  小时
                </template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item
            prop="expiration"
            label="有效期"
          >
            <div style="display: flex">
              <el-input
                v-model.number="formData.expiration"
                placeholder="时长有效期"
                style="width: 260px;"
                :max="62 * 24"
              >
                <template slot="append">
                  天
                </template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loading"
              type="primary"
              style="width: 100%;"
              @click="doRecharge('vrLimitTime')"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane> -->
      <el-tab-pane
        label="查询用户当前信息"
        name="user"
      >
        <el-form
          v-if="!isEmpty(userInfo) || userInfoLoading"
          v-loading="userInfoLoading"
          :inline="true"
          class="recharge-user-info"
        >
          <h3>基础信息</h3>
          <el-row>
            <el-col :span="24">
              <el-form-item label="userId:">
                {{ userInfo.userIdStr }}
              </el-form-item>
              <el-form-item label="用户名:">
                {{ userInfo.nickName }}
              </el-form-item>
              <el-form-item label="邮箱:">
                {{ userInfo.email }}
              </el-form-item>
              <el-form-item label="注册时间:">
                {{ userInfo.createTime }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="登录端:">
                {{ userInfo.platform }}
              </el-form-item>
              <el-form-item label="登录国家:">
                {{ userInfo.nation }}
              </el-form-item>
              <el-form-item label="登录区域:">
                {{ userInfo.zoneName || '-' }}
              </el-form-item>
              <el-form-item label="登录IP:">
                {{ userInfo.RegisterIp || '-' }}
              </el-form-item>
            </el-col>
          </el-row>

          <h3>消费信息</h3>
          <el-row>
            <el-col :span="24">
              <el-form-item label="累计消费金额:">
                {{ userInfo.PayTotalMoney }}
              </el-form-item>
              <el-form-item label="累计消费次数:">
                {{ userInfo.payCount }}
              </el-form-item>
              <el-form-item label="上一次消费时间:">
                {{ userInfo.lastConsumeTime || '-' }}
              </el-form-item>
            </el-col>
          </el-row>

          <h3>时长信息</h3>
          <el-row>
            <el-col :span="24">
              <el-form-item label="剩余时长:">
                {{ userInfo.totalTime | secondToHourFilter }}
              </el-form-item>
              <el-form-item label="订阅剩余时长:">
                {{ userInfo.subscriptionTime | secondToHourFilter }}
              </el-form-item>
              <el-form-item label="时长卡:">
                {{ userInfo.topupTime | secondToHourFilter }}
              </el-form-item>
              <el-form-item label="VR时长(有过期时间):">
                {{ userInfo.vrLimitTime | secondToHourFilter }}
              </el-form-item>
              <el-form-item label="金币:">
                {{ userInfo.goldNum || '-' }}个
              </el-form-item>
            </el-col>
          </el-row>

          <h3>订阅信息</h3>
          <el-row>
            <el-col :span="24">
              <template v-if="userInfo.isSubscription">
                <el-form-item label="订阅时长:">
                  {{ userInfo.subscriptionGoodsTime || '-' }}
                </el-form-item>
                <el-form-item label="订阅类型:">
                  {{ userInfo.subscriptionTypeDesc || '-' }}
                </el-form-item>
                <el-form-item label="订阅开始时间:">
                  {{ userInfo.subscriptionStartTime || '-' }}
                </el-form-item>
                <el-form-item label="订阅结束时间:">
                  {{ userInfo.subscriptionEndTime }}
                </el-form-item>
              </template>
              <template v-else>
                未订阅
              </template>
            </el-col>
          </el-row>
          <el-row
            style="margin-top:30px"
          >
            <el-button
              type="primary"
              @click="clickEditUserInfo(formData.userId)"
            >
              修改
            </el-button>
          </el-row>
        </el-form>
        <div
          v-else
          class="no-data"
        >
          暂无用户数据
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="用户类型"
        name="vipType"
      >
        <el-form
          ref="bifrostForm"
          :model="formData"
          :rules="vipFormRule"
          label-width="100px"
          style="width: 360px"
        >
          <el-form-item
            prop="userId"
            label="User ID"
          >
            <div style="display: flex;">
              <el-input
                v-model="formData.userId"
                style="width: 260px; margin-right: 10px;"
                placeholder="充值账号ID"
              />
              <el-button
                :loading="loading"
                type="primary"
                @click="queryVipType"
              >
                查询
              </el-button>
            </div>
          </el-form-item>
          <el-form-item
            prop="vipType"
            label="用户类型"
          >
            <el-radio-group
              v-model="formData.vipType"
            >
              <el-radio :label="0">
                普通用户
              </el-radio>
              <el-radio :label="1">
                VIP用户
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loading"
              type="primary"
              style="width: 100%;"
              @click="updateVipType"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="修改用户信息"
      :visible.sync="dialogEditUserInfoFormVisible"
    >
      <el-form
        :model="editUserInfoForm"
        label-width="150px"
        :inline="true"
      >
        <el-card shadow="never">
          <div
            slot="header"
          >
            <span style="font-weight:bold">时长信息</span>
          </div>
          <div style="padding-left:10px">
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="时长卡(小时)"
                >
                  <el-input-number
                    v-model="editUserInfoForm.topup_time"
                    placeholder="默认不修改"
                    controls-position="right"
                    :min="0"
                    :step="0.1"
                    :precision="1"
                    :step-strictly="true"
                  />
                </el-form-item>
                <el-form-item
                  label="金币(个)"
                >
                  <el-input-number
                    v-model="editUserInfoForm.gold"
                    placeholder="默认不修改"
                    controls-position="right"
                    :min="0"
                    :precision="0"
                    :step="1"
                    :step-strictly="true"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <el-card
          style="margin-top:50px"
          shadow="never"
        >
          <div
            slot="header"
          >
            <span style="font-weight:bold">VR时长(有过期时间)</span>
          </div>
          <div>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="清空时长"
                >
                  <el-switch
                    v-model="editUserInfoForm.clearVrLimitTime"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <el-card
          style="margin-top:50px"
          shadow="never"
        >
          <div
            slot="header"
          >
            <span style="font-weight:bold">订阅信息</span>
          </div>
          <div style="padding-left:10px">
            <el-row>
              <!-- <el-col :span="24">
                <el-form-item
                  label="会员类型"
                >
                  <el-select
                    v-model="editUserInfoForm.vip_type"
                    clearable
                    :disabled="editUserInfoForm.clearSubscription"
                    placeholder="默认不修改"
                  >
                    <el-option
                      label="月会员"
                      :value="1"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="会员等级"
                >
                  <el-select
                    v-model="editUserInfoForm.vip_level"
                    clearable
                    :disabled="editUserInfoForm.clearSubscription"
                    placeholder="默认不修改"
                  >
                    <el-option
                      label="黄金会员"
                      :value="1"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="订阅时间"
                >
                  <el-date-picker
                    v-model="editUserInfoForm.time"
                    :disabled="editUserInfoForm.clearSubscription"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                  />
                </el-form-item>
              </el-col> -->
              <el-col :span="24">
                <el-form-item
                  label="清空订阅信息"
                >
                  <el-switch
                    v-model="editUserInfoForm.clearSubscription"
                    @change="initEditUserInfoFormSubscriptionInfo"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogEditUserInfoFormVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="editUserInfoSubmit"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </Layout>
</template>

<script>
import { addGold, addTopUp, moveAddSubscription, addVrLimitTime, editUserInfo } from '@/api/RechargeSystem'
import { commonRequestGet, commonRequestPut } from '@/api/commonApi'

import {
  queryUserInfo
} from '@/api/customer'
import { cloneDeep, isEmpty } from 'lodash'
import { round } from '@/utils'
const MAX_GOLD_NUM = 6000
const MAX_HOUR_NUM = 62 * 24

const typeMap = {
  gold: {
    ref: 'netboomForm',
    tips: '充值金币成功',
    doAction: addGold
  },
  time: {
    ref: 'bifrostForm',
    tips: '充值时长成功',
    doAction: addTopUp

  },
  supplement: {
    ref: 'supplementForm',
    tips: '补单成功',
    doAction: moveAddSubscription
  },
  vrLimitTime: {
    ref: 'vrLimitTimeForm',
    tips: '有效期时长添加成功',
    doAction: addVrLimitTime
  }
}
export default {
  name: 'Recharge',
  filters: {
    secondToHourFilter (val) {
      return round(val / 60 / 60, -1) + 'h'
    }
  },
  data () {
    const numberCheck = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('此项必须为数字'))
      } else {
        callback()
      }
    }
    const GOLD_RANGE_CHECK = (rule, value, callback) => {
      if (value > MAX_GOLD_NUM) {
        callback(new Error(`金币数需小于${MAX_GOLD_NUM}`))
      } if (value <= 0) {
        callback(new Error('金币数需大于0'))
      } else {
        callback()
      }
    }
    const HOUR_RANGE_CHECK = (rule, value, callback) => {
      if (value > MAX_HOUR_NUM) {
        callback(new Error(`时长需小于${MAX_HOUR_NUM}小时`))
      } if (value < 0) {
        callback(new Error('小时数需大于等于0'))
      } else {
        callback()
      }
    }
    const monthCount = (rule, value, callback) => {
      if (value > 12) {
        callback(new Error('补单月数需小于12'))
      } if (value <= 0) {
        callback(new Error('补单月数需大于0'))
      } else {
        callback()
      }
    }
    const isInteger = (rule, value, callback) => {
      if (!Number.isInteger(value)) {
        callback(new Error('该项必须是整数'))
      } else {
        callback()
      }
    }
    return {
      isEmpty,
      currentTabName: 'netboom',
      formData: {
        userId: '',
        gold: '',
        appId: 20,
        time: '',
        starTime: Date.now(),
        isGoldExpiration: 1,
        timeCount: 1,
        timeType: 1,
        addTime: '',
        vipType: 0
      },
      editUserInfoForm: {
        userId: '',
        appId: 20,
        topup_time: undefined,
        gold: undefined,
        vip_type: undefined,
        time: null,
        vip_level: undefined,
        clearSubscription: false,
        clearVrLimitTime: false
      },
      dialogEditUserInfoFormVisible: false,
      TypeList: [
        {
          value: 1,
          label: '月'
        },
        {
          value: 2,
          label: '天'
        }
      ],
      openGoldExpiration: 1,
      closeGoldExpiration: 2,
      loading: false,
      netboomFormRule: {
        userId: [
          { required: true, message: '用户ID必填' },
          { validator: numberCheck }
        ],
        gold: [
          { required: true, message: '金币数必填' },
          { validator: numberCheck },
          { validator: GOLD_RANGE_CHECK },
          { validator: isInteger }
        ]
      },
      bifrostFormRule: {
        userId: [
          { required: true, message: '用户ID必填' },
          { validator: numberCheck }
        ],
        time: [
          { required: true, message: '小时数必填' },
          { validator: numberCheck },
          { validator: HOUR_RANGE_CHECK },
          { validator: isInteger }
        ]
      },
      supplementFormRule: {
        userId: [
          { required: true, message: '用户ID必填' },
          { validator: numberCheck }
        ],
        starTime: [
          { required: true, message: '开始时间必填' }
        ],
        timeCount: [
          { required: true, message: '补单时间必填' },
          { validator: numberCheck },
          { validator: monthCount },
          { validator: isInteger }
        ],
        addTime: [
          { required: true, message: '补单小时数必填' },
          { validator: numberCheck },
          { validator: HOUR_RANGE_CHECK },
          { validator: isInteger }
        ]
      },
      vrLimitTimeFormRule: {
        userId: [
          { required: true, message: '用户ID必填' },
          { validator: numberCheck }
        ],
        time: [
          { required: true, message: '小时数必填' },
          { validator: numberCheck },
          { validator: HOUR_RANGE_CHECK },
          { validator: isInteger }
        ],
        expiration: [
          { required: true, message: '有效期必填' },
          { validator: numberCheck },
          { validator: HOUR_RANGE_CHECK },
          { validator: isInteger }
        ]
      },
      vipFormRule: {
        userId: [
          { required: true, message: '用户ID必填' },
          { validator: numberCheck }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          return false
          // return time.getTime() > Date.now() || time.getTime() < Date.now() - 7 * 24 * 60 * 60 * 1000
        }
      },
      userInfo: {},
      userInfoLoading: false
    }
  },
  watch: {
    currentTabName (name) {
      if (name === 'user') {
        if (this.formData.userId) {
          this.queryUserInfo()
        } else {
          this.userInfo = {}
        }
      }
    }
  },
  methods: {
    queryVipType () {
      commonRequestGet('admin/accounts/vip_type', { user_id: this.formData.userId }).then(res => {
        this.formData.vipType = +res.vip_type
      })
    },
    updateVipType () {
      commonRequestPut('admin/accounts/vip_type', { user_id: this.formData.userId, vip_type: this.formData.vipType }).then(res => {
        this.$notify({
          title: '成功',
          message: '用户类型修改成功',
          type: 'success'
        })
      })
    },
    queryUserInfo () {
      this.userInfoLoading = true
      queryUserInfo({ userId: this.formData.userId, appId: 20 })
        .then(res => {
          if (res.code === 200) {
            this.userInfo = res.data || {}
          } else {
            this.userInfo = {}
          }
        })
        .finally(() => {
          this.userInfoLoading = false
        })
    },
    doRecharge (type) {
      const { [type]: { ref, tips, doAction } } = typeMap
      this.$refs[ref].validate(valid => {
        if (valid) {
          this.loading = true
          const {
            userId,
            gold,
            time,
            starTime,
            isGoldExpiration,
            timeCount,
            timeType,
            addTime,
            expiration
          } = cloneDeep(this.formData)
          let formData

          if (type === 'gold') {
            formData = { userId, gold, appId: 20, isGoldExpiration, timeCount, timeType }
          }
          if (type === 'time') {
            formData = { userId, time: time * 60 * 60, appId: 12 }
          }
          if (type === 'supplement') {
            formData = {
              userId,
              starTime: Math.floor(starTime / 1000),
              timeCount,
              timeType,
              addTime
            }
          }
          if (type === 'vrLimitTime') {
            formData = {
              userId,
              time,
              expiration,
              appId: 20
            }
          }
          doAction(formData)
            .then(res => {
              if (res.code === 200) {
                this.$refs[ref].resetFields()
                this.$notify({
                  title: '成功',
                  message: tips,
                  type: 'success'
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    clickEditUserInfo (userId) {
      this.initEditUserInfoForm()
      this.dialogEditUserInfoFormVisible = true
    },
    editUserInfoSubmit () {
      var data = JSON.parse(JSON.stringify(this.editUserInfoForm))
      if (data.time !== null) {
        data.subscription_start_time = data.time[0]
        data.subscription_end_time = data.time[1]
      }
      delete data.time
      if (data.topup_time === round(this.userInfo.topupTime / 60 / 60, -1)) {
        data.topup_time = undefined
      }
      if (data.gold === this.userInfo.goldNum) {
        data.gold = undefined
      }
      const params = Object.keys(data)
        .filter((key) => data[key] !== null && data[key] !== undefined && data[key] !== '')
        .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
      params.userId = this.formData.userId
      editUserInfo({ ...params, appId: 20 })
        .then(res => {
          if (res.code === 200 && res.data.success) {
            this.$notify({
              title: '成功',
              message: res.data.msg,
              type: 'success'
            })
          } else {
            this.$notify({
              title: '失败',
              message: res.data.msg,
              type: 'error'
            })
          }
        })
        .finally(() => {
          this.dialogEditUserInfoFormVisible = false
          this.queryUserInfo()
        })
    },
    initEditUserInfoFormSubscriptionInfo (val) {
      if (val === true) {
        this.editUserInfoForm.time = null
        this.editUserInfoForm.vip_type = undefined
        this.editUserInfoForm.vip_level = undefined
      }
    },
    initEditUserInfoForm () {
      this.editUserInfoForm = {
        userId: '',
        appId: 20,
        topup_time: round(this.userInfo.topupTime / 60 / 60, -1),
        gold: this.userInfo.goldNum,
        vip_type: undefined,
        time: null,
        vip_level: undefined,
        clearSubscription: false,
        clearVrLimitTime: false
      }
    }
  }
}
</script>

<style lang="less">
.recharge-pages {

  .recharge-user-info {
    .el-form-item {
      margin-bottom: 0;
      min-width: 220px;
      .el-form-item__label {
        color: #666;
        font-weight: bold;
      }
    }
    h3 {
      font-size: 14px;
      margin: 24px 0 0;
    }
  }
  .no-data {
    text-align: center;
    margin: 50px 0;
  }
}
</style>
